import {$, $$} from '../utils/query-selector.js';
import dialogPolyfill from 'dialog-polyfill';
import {init as initEmbeds} from '../components/embed.js';
import {init as initInstagramGalleries} from '../components/instagram-gallery.js';

class Posts {
	constructor($el) {
		this.isOpen = false;
		this.$el = $el;
		this.$$links = $$('[js-posts__link]', this.$el);
		this.$dialog = $('[js-posts__dialog]', this.$el);
		dialogPolyfill.registerDialog(this.$dialog);
		this.$dialogContent = $('[js-posts__dialog-content]', this.$el);

		this.$$links.forEach(($item, i) => {
			$item.setAttribute('role', 'button');
			$item.addEventListener('click', (e) => {
				e.preventDefault();
				this.openPost(e.currentTarget.href);
			});
		});

		this.$dialog.addEventListener('close', () => {
			this.$dialogContent.innerHTML = '';
			this.isOpen = false;
		});
	}

	async openPost(url) {
		if (this.isOpen) {
			return;
		}

		this.isOpen = true;

		const response = await fetch(url + '?ajax=1');

		if (!response.ok) {
			this.$dialogContent.innerHTML = `
				<p class="text-preset-h3 text-center">
					Hoppla, da ist leider was schief gelaufen! 😵
				</p>`;
			this.$dialog.showModal();
			return;
		}

		this.$dialogContent.innerHTML = await response.text();

		const embeds = initEmbeds(this.$dialogContent);
		initInstagramGalleries(this.$dialogContent);

		this.$dialog.showModal();

		if (embeds.length > 0) {
			embeds[0].load();
		}
	}
}

$$('[js-posts]').forEach(($el) => new Posts($el));
