// import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import Headroom from 'headroom.js';
import {$} from '../utils/query-selector.js';

const MENU_BREAKPOINT = '(min-width: 64em)'; // >= 768px
const KEYCODE_TAB = 9;

class Menu {
	constructor($el) {
		if (!$el) {
			return;
		}

		this.$el = $el;
		this.$toggle = $('[js-menu__toggle]', this.$el);
		this.$popup = $('[js-menu__popup]', this.$el);
		this._focusTrapCallback = this.getFocusTrapCallback();

		this.headroom = new Headroom(this.$el, {
			offset: 300,
			tolerance: {
				up: 5,
				down: 0,
			},
			classes: {
				initial: 'has-headroom',
				pinned: 'is-pinned',
				unpinned: 'is-unpinned',
				top: 'is-top',
				notTop: 'is-not-top',
				bottom: 'is-bottom',
				notBottom: 'is-not-bottom',
				frozen: 'is-frozen',
			},
		});
		this.headroom.init();

		this._closeCallback = (e) => {
			if (this.$el.contains(e.target)) {
				// do not close menu when clicking inside
				return;
			}

			this.toggle(false);
		};

		this.$toggle.addEventListener('click', this.toggle.bind(this));

		matchMedia(MENU_BREAKPOINT).addEventListener('change', (query) => {
			if (query.matches) {
				this.toggle(false);
			}
		});

		this.$el.addEventListener('keydown', (e) => {
			if (!this.isOpen) {
				return;
			}

			if (e.key === 'Escape') {
				this.toggle(false);
				this.$toggle.focus();
			}
		});
	}

	get isOpen() {
		return this.$toggle.getAttribute('aria-expanded') === 'true';
	}

	get focusableElements() {
		// source: https://github.com/gdkraus/accessible-modal-dialog/blob/master/modal-window.js#L38
		return [
			...this.$el.querySelectorAll(
				[
					'a[href]',
					'area[href]',
					'input:not([disabled])',
					'select:not([disabled])',
					'textarea:not([disabled])',
					'button:not([disabled])',
					'iframe',
					'object',
					'embed',
					'*[tabindex]',
					'*[contenteditable]',
				].join(', '),
			),
		].filter(($item) => $item.offsetParent !== null); // filter out hidden elements
	}

	getFocusTrapCallback() {
		return (e) => {
			if (e.key !== 'Tab' || e.keyCode !== KEYCODE_TAB) {
				return;
			}

			const $$focusable = this.focusableElements;
			const $firstFocusable = $$focusable[0];
			const $lastFocusable = $$focusable[$$focusable.length - 1];
			const currentIndex = [...$$focusable].findIndex(
				($item) => $item === document.activeElement,
			);

			if (e.shiftKey) {
				if (document.activeElement === $firstFocusable) {
					e.preventDefault();
					$lastFocusable.focus();
				} else if (currentIndex !== -1) {
					e.preventDefault();
					$$focusable[currentIndex - 1].focus();
				}
			} else {
				if (document.activeElement === $lastFocusable) {
					e.preventDefault();
					$firstFocusable.focus();
				} else if (currentIndex !== -1) {
					e.preventDefault();
					$$focusable[currentIndex + 1].focus();
				}
			}
		};
	}

	toggle(force = null) {
		if (!this.$el) {
			return;
		}

		const newState = typeof force === 'boolean' ? force : !this.isOpen;

		this.$toggle.setAttribute('aria-expanded', newState);
		this.$el.dataset.isOpen = newState.toString();

		if (newState) {
			window.addEventListener('click', this._closeCallback);

			// set focus to first item
			this.focusableElements[0].focus();

			window.addEventListener('keydown', this._focusTrapCallback);
		} else {
			window.removeEventListener('keydown', this._focusTrapCallback);
			window.removeEventListener('click', this._closeCallback);
		}
	}
}

export default new Menu($('[js-menu]'));
