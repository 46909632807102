/* Based on https://github.com/twbs/bootstrap/blob/main/js/src/scrollspy.js */
import {$, $$} from '../utils/query-selector.js';

const OBSERVER_OPTIONS = {
	rool: null,
	rootMargin: '0px 0px -25%',
	threshold: [0.1, 0.5, 1],
};

const MEDIA_QUERY = matchMedia('(min-width: 48em)');

class ScrollNavigation {
	constructor($el) {
		this.$el = $el;

		this.targetLinks = new Map();
		this.observableSections = new Map();
		this.activeTarget = null;
		this.observer = null;
		this.previousScrollData = {
			visibleEntryTop: 0,
			parentScrollTop: 0,
		};

		// this.initializeTargetsAndObservables();

		// this.observer = new IntersectionObserver(
		// 	(entries) => this.observerCallback(entries),
		// 	OBSERVER_OPTIONS,
		// );

		new IntersectionObserver(
			([e]) =>
				(e.target.dataset.isPinned = (e.intersectionRatio < 1).toString()),
			{threshold: [1]},
		).observe($el);

		// for (const $section of this.observableSections.values()) {
		// 	this.observer.observe($section);
		// }
	}

	// observerCallback(entries) {
	// 	const targetElement = (entry) =>
	// 		this.targetLinks.get(`#${entry.target.id}`);
	// 	const activate = (entry) => {
	// 		this.previousScrollData.visibleEntryTop = entry.target.offsetTop;
	// 		this.setActiveLink(targetElement(entry));
	// 	};

	// 	const parentScrollTop = window.scrollY;
	// 	const userScrollsDown =
	// 		parentScrollTop >= this.previousScrollData.parentScrollTop;
	// 	this.previousScrollData.parentScrollTop = parentScrollTop;

	// 	for (const entry of entries) {
	// 		if (!entry.isIntersecting) {
	// 			this.activeTarget = null;
	// 			// this.clearActiveClass(targetElement(entry));
	// 			this.setActiveLink(null);
	// 			continue;
	// 		}

	// 		const entryIsLowerThanPrevious =
	// 			entry.target.offsetTop >= this.previousScrollData.visibleEntryTop;

	// 		// if we are scrolling down, pick the bigger offsetTop
	// 		if (userScrollsDown && entryIsLowerThanPrevious) {
	// 			activate(entry);
	// 			// if parent isn't scrolled, let's keep the first visible item, breaking the iteration
	// 			if (!parentScrollTop) {
	// 				return;
	// 			}

	// 			continue;
	// 		}

	// 		// if we are scrolling up, pick the smallest offsetTop
	// 		if (!userScrollsDown && !entryIsLowerThanPrevious) {
	// 			activate(entry);
	// 		}
	// 	}
	// }

	// initializeTargetsAndObservables() {
	// 	this.targetLinks = new Map();
	// 	this.observableSections = new Map();

	// 	const targetLinks = $$('a', this.$el);

	// 	for (const anchor of targetLinks) {
	// 		// ensure that the anchor has an id and is not disabled
	// 		if (!anchor.hash) {
	// 			continue;
	// 		}

	// 		const observableSection = document.getElementById(
	// 			anchor.hash.replace(/^#/, ''),
	// 		);

	// 		if (observableSection) {
	// 			this.targetLinks.set(anchor.hash, anchor);
	// 			this.observableSections.set(anchor.hash, observableSection);
	// 		}
	// 	}
	// }

	// setActiveLink(target) {
	// 	if (this.activeTarget === target) {
	// 		return;
	// 	}

	// 	this.targetLinks.forEach(($link) => {
	// 		const isCurrent = $link === target;
	// 		$link.setAttribute('aria-current', isCurrent.toString());

	// 		if (isCurrent && !MEDIA_QUERY.matches) {
	// 			$link.scrollIntoView({behavior: 'smooth'});
	// 		}
	// 	});

	// 	this.activeTarget = target;
	// }
}

$$('[js-scrollspy]').forEach(($el) => new ScrollNavigation($el));
