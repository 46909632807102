import {$, $$} from '../utils/query-selector.js';
import escape from '../utils/escape.js';
import Swiper, {A11y, Keyboard, Pagination, Navigation} from 'swiper';

import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/keyboard';

class InstagramGallery {
	constructor($el) {
		this.$el = $el;
		this.$swiper = $('[js-instagram-gallery__swiper]', this.$el);
		this.$$slides = $$('[js-instagram-gallery__slide]');

		const title = this.$el.dataset.title;
		const slidesCount = this.$$slides.length;

		this.swiper = new Swiper(this.$swiper, {
			modules: [A11y, Keyboard, Navigation, Pagination],
			loop: false,
			grabCursor: true,
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				renderBullet(index, className) {
					return `<li><button
						aria-label="${escape(title)} Folie ${index + 1} von ${slidesCount}"
						data-index="${index}"
						class="${className} block w-4 h-4 bg-white cursor-pointer
							border border-2 border-black rounded-full shadow-[1.5px_1.5px_0_0_black]
							transition-colors aria-[current]:bg-orange
							hover:bg-neongreen aria-[current]:hover:bg-orange"
						js-swiper__pagination-bullet></button></li>`;
				},
				clickable: true,
			},
			keyboard: true,
			a11y: {
				enabled: true,
				firstSlideMessage: 'Erstes Bild',
				lastSlideMessage: 'Letztes Bild',
				nextSlideMessage: 'Nächstes Bild',
				prevSlideMessage: 'Vorheriges Bild',
			},
			navigation: {
				prevEl: '[js-instagram-gallery__prev]',
				nextEl: '[js-instagram-gallery__next]',
				disabledClass: 'is-disabled',
				lockClass: 'is-locked',
				hiddenClass: 'is-hidden',
				navigationDisabledClass: 'is-disabled',
			},
		});

		// additional handler needed, since Swiper itself does not work
		// when a bullet consists of more than one element
		this.$swiper.addEventListener('click', (e) => {
			const $bullet = e.target.closest('[js-swiper__pagination-bullet]');

			if (!$bullet) {
				return;
			}

			this.swiper.slideToLoop(parseInt($bullet.dataset.index, 10));
			e.stopImmediatePropagation();
		});
	}
}

function init(context = document) {
	const galleries = [];

	$$('[js-instagram-gallery]', context).forEach(($el) =>
		galleries.push(new InstagramGallery($el)),
	);

	return galleries;
}

export {init};

init();

export default InstagramGallery;
