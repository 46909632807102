import {$, $$} from '../utils/query-selector.js';
import dialogPolyfill from 'dialog-polyfill/index.js';

const STORAGE_KEY = window.seedling.consentStorageKey;

function compareArrays(a, b) {
	return JSON.stringify(a) === JSON.stringify(b);
}

class Consent {
	constructor() {
		this.$settingsDialog = $('[js-consent__settings]');
		dialogPolyfill.registerDialog(this.$settingsDialog);
		this.$settingsDialog.setAttribute('tabindex', '-1');
		this.$confirmDialog = $('[js-consent__confirm]');
		dialogPolyfill.registerDialog(this.$confirmDialog);
		this.$confirmDialog.setAttribute('tabindex', '-1');
		this.$form = $('[js-consent__form]', this.$settingsDialog);
		this.initDialog();
		this.initLinks();
		this._consent = this.readSetting();

		if (!document.documentElement.hasAttribute('data-disable-tracking')) {
			if (this._consent === null) {
				this.open();
			} else {
				this.triggerEvent();
			}
		}
	}

	open() {
		const chosen = Array.isArray(this._consent) ? this._consent : ['necessary'];

		$$('input[type=checkbox]', this.$form).forEach(($item) => {
			$item.checked = chosen.includes($item.value);
		});

		this.$settingsDialog.showModal();
		this.$settingsDialog.focus();
		setTimeout(() => this.$settingsDialog.scrollTo(0, 0), 0);
	}

	initDialog() {
		// disable ESC key
		this.$settingsDialog.addEventListener('cancel', (e) => e.preventDefault());

		// set return value, when dialog form is submitted
		const submitCallback = (e) => {
			const submitterValue = e.currentTarget.value;

			if (submitterValue === 'all') {
				$$('[name="consent"]', this.$form).forEach(
					($el) => ($el.checked = true),
				);
			}

			if (submitterValue === 'recommended') {
				$$(
					'[name="consent"][value="necessary"], [name="consent"][value="embed"]',
					this.$form,
				).forEach(($el) => ($el.checked = true));
			}

			// do not serialize form to include disabled elements as well
			this.$settingsDialog.returnValue = $$(
				"[name='consent']:checked",
				this.$form,
			)
				.map(($el) => $el.value)
				.join(',');

			// manually close dialog, so the returnValue is not overridden
			// by the browser’s default behavior
			e.preventDefault();
			this.$settingsDialog.close();
		};

		$$('[type=submit]', this.$form).forEach(($n) =>
			$n.addEventListener('click', submitCallback),
		);

		// update consent, when dialog is closed
		this.$settingsDialog.addEventListener('close', (e) => {
			this.updateConsent(e.target.returnValue.split(','));
		});
	}

	initLinks() {
		$$('[js-open-consent]').forEach(($el) =>
			$el.addEventListener('click', (e) => {
				e.preventDefault();
				this.open();
			}),
		);
	}

	updateConsent(value, reload = true) {
		value.sort();

		const previousConsent = this._consent;

		this._consent = value;
		this.saveSetting(value);

		if (
			reload &&
			previousConsent !== null &&
			!compareArrays(this._consent, previousConsent)
		) {
			const result = window.confirm(
				'Die geänderten Einstellungen haben treten möglicherweise erst nach dem Neuladen der Seite in Kraft. Soll die Seite neu geladen werden?',
			);

			if (result) {
				window.location.reload();
				return;
			}
		}

		this.triggerEvent();
	}

	addConsent(name, reload = true) {
		if (!Array.isArray(this._consent)) {
			this.updateConsent([name], reload);
		}

		if (this._consent.includes(name)) {
			return;
		}

		this.updateConsent([...this._consent, name], reload);
	}

	triggerEvent() {
		window.dispatchEvent(
			new CustomEvent('consent', {
				detail: this._consent,
			}),
		);
	}

	given(name) {
		return new Promise((resolve, reject) => {
			if (this._consent !== null) {
				// consent already given or rejected by user
				if (this._consent.includes(name)) {
					resolve();
				} else {
					reject();
				}

				return;
			}

			window.addEventListener(
				'consent',
				(e) => {
					if (e.detail.includes(name)) {
						resolve();
					} else {
						reject();
					}
				},
				{once: true},
			);
		});
	}

	ask(name = 'embed', options = {}) {
		if (name !== 'embed') {
			throw 'you can only ask for `embed` at this time';
		}

		options = {
			providerName: 'PROVIDER',
			providerUrl: 'https://example.com',
			providerPrivacyUrl: 'https://example.com/privacy',
			...options,
		};

		if (Array.isArray(this._consent) && this._consent.includes(name)) {
			return Promise.resolve('always');
		}

		$$('[js-consent__confirm-provider]', this.$confirmDialog).forEach(($n) => {
			$n.textContent = options.providerName;
			$n.href = options.providerUrl;
		});
		$$('[js-consent__confirm-ptovider-privacy]', this.$confirmDialog).forEach(
			($n) => ($n.href = options.providerPrivacyUrl),
		);
		$$('[js-consent__confirm-provider-name]', this.$confirmDialog).forEach(
			($n) => ($n.textContent = options.providerName),
		);

		this.$confirmDialog.showModal();
		this.$confirmDialog.focus();
		setTimeout(() => this.$confirmDialog.scrollTo(0, 0), 0);

		$('button[value=always]', this.$confirmDialog).focus();

		const $$buttons = $$('[type="submit"]', this.$confirmDialog);

		return new Promise((resolve, reject) => {
			const _callback = (e) => {
				e.preventDefault();

				$$buttons.forEach(($el) => $el.removeEventListener('click', _callback));
				this.$confirmDialog.close();

				if (e.currentTarget.value === 'always') {
					this.addConsent(name, false);
					resolve('always');
					return;
				}

				if (e.currentTarget.value === 'once') {
					resolve('once');
					return;
				}

				reject();
			};

			$$buttons.forEach(($el) => $el.addEventListener('click', _callback));
		});
	}

	saveSetting(value) {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(value));
		// const expires = new Date(
		// 	new Date().getTime() + 1000 * 60 * 60 * 24 * 365,
		// ).toGMTString();

		// document.cookie = `${STORAGE_KEY}=${encodeURIComponent(
		// 	value.join(','),
		// )}; expires=${expires}; path=/; SameSite=Strict`;
	}

	readSetting() {
		const value = localStorage.getItem(STORAGE_KEY);

		return value !== null ? JSON.parse(value) : null;
		// const v = document.cookie.match('(^|;) ?' + STORAGE_KEY + '=([^;]*)(;|$)');
		// return v ? decodeURIComponent(v[2]).split(',') : null;
	}
}

const consent_manager = new Consent();

export default consent_manager;

const consent = function (name) {
	return consent_manager.given(name);
};

const ask = function (name, options) {
	return consent_manager.ask(name, options);
};

export {consent, ask};
