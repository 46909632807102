import '../css/index.css';

import './components/accordion.js';
import './components/consent.js';
import './components/copy-to-clipboard.js';
import './components/embed.js';
import './components/home-hero.js';
import './components/ideologies.js';
import './components/infographic.js';
import './components/instagram-gallery.js';
import './components/menu.js';
import './components/posts.js';
import './components/scrollspy.js';
import './components/text-balancer.js';
import './components/tracking.js';

import('@dotlottie/player-component');

// needed for fixing browser-specific bugs
const isSafari = (function () {
	const ua = navigator.userAgent.toLowerCase();
	if (ua.indexOf('safari') != -1) {
		if (ua.indexOf('chrome') > -1) {
			return false;
		} else {
			return true;
		}
	}
	return false;
})();

if (isSafari) {
	document.documentElement.classList.add('is-safari');
}

// dialog support
const supportsDialog = !!window.HTMLDialogElement;
document.documentElement.classList.add(
	supportsDialog ? 'supports-dialog' : 'supports-not-dialog',
);
